const ReferenceData: object = [
  {
    orgFields: [
      {
        val: "registerno",
        label: "Регистрийн дугаар"
      },
      {
        val: "stateregisterno",
        label: "Улсын бүртгэлийн дугаар"
      }
    ],
    legalStatus: [
      {
        value: "01",
        label: "Монгол улсын иргэн"
      },
      {
        value: "02",
        label: "Гадаад иргэн"
      },
      {
        value: "03",
        label: "Хулийн этгээд /дотоод/"
      },
      {
        value: "04",
        label: "Хулийн этгээд /гадаад/"
      }
    ],
    citizenFields: [
      {
        val: "cin",
        label: "CIN"
      },
      {
        val: "registerno",
        label: "Регистрийн дугаар"
      }
    ],
    coStatus: [
      {
        coValue: "01",
        coLabel: "Холбогдох иргэн"
      },
      {
        coValue: "02",
        coLabel: "Холбогдох байгууллага"
      }
    ],
    coFieldsCitizen: [
      {
        coVal: "cin",
        coLabel: "CIN"
      },
      {
        coVal: "registerno",
        coLabel: "Регистрийн дугаар"
      }
    ],
    coFieldsOrg: [
      {
        coVal: "registerno",
        coLabel: "Регистрийн дугаар"
      },
      {
        coVal: "stateregisterno",
        coLabel: "Улсын бүртгэлийн дугаар"
      }
    ],
    tableData: [
      {
        date: "1",
        name: "Tom",
        state: "California",
        city: "Los Angeles",
        address: "No. 189, Grove St, Los Angeles",
        zip: "CA 90036"
      },
      {
        date: "2",
        name: "Tom",
        state: "California",
        city: "Los Angeles",
        address: "No. 189, Grove St, Los Angeles",
        zip: "CA 90036"
      }
    ]
  }
];

export default ReferenceData;
