
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import referenceData from "@/data/reference";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import moment from "moment";
import { useRouter } from "vue-router";
import { saveFile } from "@/core/services/FileSaverService";
import store from "@/store";

export default defineComponent({
  name: "reference",
  setup() {
    onMounted(async () => {
      setCurrentPageTitle("Лавлагаа");
    });
    const role = store.getters.getRoleNames as string[];
    const isu = store.getters.getMbUser as boolean;

    return {
      role,
      isu
    };
  },
  data() {
    const router = useRouter();
    const getCustomerDetails = identityno => {
      console.log(identityno)
      const id = this.b64EncodeUnicode(identityno);
      console.log(id)
      router.push({
        name: "refcustomerdetails",
        params: { id: id }
      });
    };

    return {
      getCustomerDetails,
      zmaChecked: false,
      inquiryDownloadButton: "off",
      adv: {
        type: "adv",
        status: "01",
        input: "",
        select1: "and",
        input1: "",
        select2: "and",
        input2: "",
        select3: "and",
        input3: ""
      },
      loadButtonSimple: "off",
      result: false,
      coRelResultCode: -1,
      customerResultCode: -1,
      searchValue: "",
      coSearchValue: "",
      legalStatus: referenceData[0]["legalStatus"],
      value: "01",
      orgFields: referenceData[0]["orgFields"],
      citizenFields: referenceData[0]["citizenFields"],
      val: "cin",
      coStatus: referenceData[0]["coStatus"],
      coValue: "01",
      coFieldsCitizen: referenceData[0]["coFieldsCitizen"],
      coFieldsOrg: referenceData[0]["coFieldsOrg"],
      coVal: "cin",
      searchedValue: "",
      citizenTableData: [],
      orgTableData: [],
      coRelCitizenTableData: [],
      coRelOrgTableData: []
    };
  },
  // mounted() {
  //
  // },
  methods: {
    resetResultFieldsLegalStatus() {
      if (this.value === "01" || this.value === "02") {
        this.val = "cin";
      } else {
        this.val = "registerno";
      }

      this.result = false;
      this.coRelResultCode = -1;
      this.customerResultCode = -1;
    },
    resetResultFieldsCoLegalStatus() {
      if (this.coValue === "01") {
        this.coVal = "cin";
      } else {
        this.coVal = "registerno";
      }

      this.result = false;
      this.coRelResultCode = -1;
      this.customerResultCode = -1;
    },
    resetResultFields() {
      this.result = false;
      this.coRelResultCode = -1;
      this.customerResultCode = -1;
    },
    getMomentTime(date) {
      return moment(date).format("yyyy-MM-DD hh:mm");
    },
    async getCustomerRefPdf(customercode) {
      this.inquiryDownloadButton = "on";
      document.body.classList.add("waiting");
      let slug;
      if (this.zmaChecked)
        slug = `?customercode=${customercode}&ischecked=${this.zmaChecked}`;
      else slug = `?customercode=${customercode}`;
      await ApiService.get(`refcustomerpdf`, slug)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            saveFile(results.response, customercode, "application/pdf", ".pdf");
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Лавлагаа татхад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          document.body.classList.remove("waiting");
          this.inquiryDownloadButton = "off";
        });
    },
    async getCoCustomerReference() {
      this.loadButtonSimple = "on";
      (document.getElementById(
        "searchValueRel"
      ) as HTMLInputElement).setAttribute("disabled", "true");
      const params = {
        type: "custrelation",
        searchValue: this.coSearchValue,
        status: this.coValue,
        searchType: this.coVal
      };
      await ApiService.post("reference", { params })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            const custResult = results.response.searchResult;
            this.result = true;
            this.coRelResultCode = custResult.code;
            console.log(custResult)
            if (this.coRelResultCode !== 0) {
              if (this.coValue === "01") {
                this.coRelCitizenTableData = custResult.result;
              } else {
                this.coRelOrgTableData = custResult.result;
              }
            } else {
              ElNotification({
                title: "Хамтран зээлдэгч",
                message: "Үр дүн олдсонгүй.",
                type: "info",
                iconClass: "el-text-success"
              });
            }
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Хамтран зээлдэгч хайхад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          (document.getElementById(
            "searchValueRel"
          ) as HTMLInputElement).removeAttribute("disabled");
          this.searchedValue = this.coSearchValue;
          this.customerResultCode = -1;
          this.loadButtonSimple = "off";
        });
    },
    async getCustomerReference() {
      this.loadButtonSimple = "on";
      (document.getElementById(
        "searchValueSimple"
      ) as HTMLInputElement).setAttribute("disabled", "true");
      const params = {
        type: "simple",
        searchValue: this.searchValue,
        status: this.value,
        searchType: this.val
      };
      await ApiService.post("reference", { params })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            const custResult = results.response.searchResult;
            this.result = true;
            this.customerResultCode = custResult.code;
            if (this.customerResultCode !== 0) {
              if (this.value === "01" || this.value === "02") {
                this.citizenTableData = custResult.result;
              } else {
                this.orgTableData = custResult.result;
              }
            } else {
              ElNotification({
                title: "Зээлдэгч энгийн хайлт",
                message: "Үр дүн олдсонгүй.",
                type: "info",
                iconClass: "el-text-info"
              });
            }
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Зээлдэгч хайхад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          (document.getElementById(
            "searchValueSimple"
          ) as HTMLInputElement).removeAttribute("disabled");
          this.searchedValue = this.searchValue;
          this.coRelResultCode = -1;
          this.loadButtonSimple = "off";
        });
    }
  }
});
